<template>
  <div>
    <b-modal size="sm" class="modal-size" hide-footer v-model="modal_table" title="EDITAR MESA">
      <b-row>
        <b-col md="12">
           <b-form id="Form" @submit.prevent="Validate">
              <b-row>
                <b-col md="12">
                  <b-form-group label="Sala :">
                    <b-form-select  v-model="table.id_room" :options="table.rooms"></b-form-select>
                  </b-form-group>
                  <small v-if="errors.id_room"  class="form-text text-danger" >Seleccione una sala</small>
               
                  <b-form-group label="Nombre :">
                    <b-form-input  v-model="table.name" ></b-form-input>
                  </b-form-group>
                  <small v-if="errors.name"  class="form-text text-danger" >Ingrese un nombre</small>

                  <b-form-group label="Descripción :">
                    <b-form-textarea  v-model="table.description" ></b-form-textarea>
                  </b-form-group>
                  <small v-if="errors.name"  class="form-text text-danger" >Ingrese un nombre</small>

                  <b-form-group label="Tipo :">
                    <b-form-select  v-model="table.static" :options="type_table"></b-form-select>
                  </b-form-group>

                  <b-form-group label="Estado :">
                    <b-form-select  v-model="table.state" :options="state"></b-form-select>
                  </b-form-group>
                </b-col>

                 <b-col md="12">
                  <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar</b-button>
                </b-col>
              </b-row>
             </b-form>
        </b-col>
  
      </b-row>
    </b-modal>
  </div>
</template>

<style>


</style>

<script>

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import EventBus from "@/assets/js/EventBus";
export default {
  name: "ModalClient",
  components:{
  },
  data() {
    return {
        module:'Table',
        role:3,
        modal_table: false,
        id_table:'',
        table:{
          rooms:[],
          id_room:'',
          name:'',
          description:'',
          x:'',
          y:'',
          w:'',
          h:'',
          i:'',
          static:'0',
          state:'1',
        },
        state:[
          {value:1,text:'Activo'},
          {value:0,text:'Inactivo'},
        ],
        type_table:[
          {value:1,text:'Pasadiso'},
          {value:0,text:'Mesa'},
        ],
        errors:{
          id_room:false,
          name:false,
        },
        validate: false,

    };
  },
  created (){

  },
  mounted () {
    EventBus.$on('ModalEditTableShow', (id_table) => {
      this.id_table = id_table;
      this.modal_table = true;
      this.ListRoom();
      this.ViewTable();
    });
    
  },
  methods: {
    ListRoom,
    ViewTable,
    Validate,
    EditTable,
  },
  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    }
  },
};

function ListRoom() {
   let me = this;
  let url = this.url_base + "room/list-active";
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token,module: this.module,role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.table.rooms = [{value:'',text:'-- Seleccione una opción --'}];
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index];
          me.table.rooms.push({value: element.id_room,text:element.name});
        }
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function ViewTable() {
   let me = this;
  let url = this.url_base + "table/view/"+this.id_table;
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token,module: this.module,role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.table.id_table = response.data.result.id_table;
        me.table.id_room = response.data.result.id_room;
        me.table.name = response.data.result.name;
        me.table.description = response.data.result.description;
        me.table.static = response.data.result.static;
        me.table.state = response.data.result.state;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}


function Validate() {
  
  this.errors.id_room = this.table.id_room.length == 0 ? true : false;
  this.errors.name = this.table.name.length == 0 ? true : false;
  if (this.errors.id_room) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.name) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }

  this.EditTable();

}

function EditTable() {
  let me = this;
  let url = me.url_base + "table/edit";
  let data = {
    id_table: me.table.id_table,
    id_room: me.table.id_room,
    name:me.table.name,
    description:me.table.description,
    i:me.table.name,
    static:me.table.static,
    state: me.table.state,
  };
  me.isLoading = true;
  axios({
    method: "PUT",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: 2,},
  })
  .then(function (response) {
    if (response.data.status == 200) {
      EventBus.$emit('RefreshListTable');
      me.modal_table = false;
      Swal.fire({ icon: 'success', text:response.data.message , timer: 3000,})
    } else {
      Swal.fire({ icon: 'error', text:response.data.message , timer: 3000,})
    }
    me.isLoading = false;
  })
  .catch((error) => {
    console.log(error)
    Swal.fire({ icon: 'error', text: 'A ocurrido un error en la gra', timer: 3000,})
    me.isLoading = false;
  });
}
</script>
